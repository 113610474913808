<template>
  <v-container fluid>
    <Loader :loading="loading" />
    <div class="container-padding">
      <v-stepper v-model="e1" class="animate__animated animate__bounceInLeft my-0" non-linear>
        <v-stepper-header>
          <template v-for="(step, n) in steps">
            <v-stepper-step :complete="e1 > n + 1" :step="n + 1" v-bind:key="n" editable edit-icon="$complete">{{ e1 == n + 1 ? "" : step }}</v-stepper-step>
            <v-divider v-if="n + 1 !== steps.length" v-bind:key="step"></v-divider>
          </template>
        </v-stepper-header>
      </v-stepper>
      <br />
      <v-expansion-panels hover v-model="panel" class="pa-0 my-6">
        <v-expansion-panel class="animate__animated animate__bounceInRight mb-0x">
          <v-expansion-panel-header color="primary" class="py-0">
            <v-toolbar-title class="white--text" tag="h2">Datos del solicitante</v-toolbar-title>
            <template v-slot:actions>
              <v-icon color="white"> $expand </v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="px-0">
            <v-form ref="formPerson" lazy-validation>
              <div class="container-padding">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.clave_elector" @input="forms.person.clave_elector = forms.person.clave_elector.toUpperCase()" label="Clave de elector*" prepend-icon="mdi-card-account-details-outline" :rules="rules.max18" :disabled="loading" outlined dense required>
                      <template slot="append-outer">
                        <div style="bottom: 10px; position: relative">
                          <v-btn color="primary" @click="getValidate('person')" :disabled="forms.person.clave_elector.length == 0" small fab>
                            <v-icon>mdi-magnify</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.apellido_paterno" label="Apellido paterno*" prepend-icon="mdi-alpha-p" :rules="rules.onlyText" :disabled="loading || validClient" outlined dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.apellido_materno" label="Apellido materno" prepend-icon="mdi-alpha-m" :rules="rules.onlyText" :disabled="loading || validClient" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.nombre" label="Nombre(s)*" prepend-icon="mdi-account" :rules="rules.onlyText" :disabled="loading || validClient" outlined dense required></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model.number="requestedAmount" label="Monto solicitado*" type="number" min="0" prepend-icon="mdi-account-cash-outline" prepend-inner-icon="mdi-currency-usd" :disabled="loading || validClient" outlined dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model.number="suggestedAmount" label="Monto sugerido*" type="number" min="0" prepend-icon="mdi-cash-check" prepend-inner-icon="mdi-currency-usd" disabled outlined dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete v-model="companyId" label="Empresa*" :items="company" item-text="nombre" item-value="idempresa" prepend-icon="mdi-domain" :disabled="loading || validClient" outlined dense required></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete v-model="groupId" label="Grupo*" :items="group" item-text="nombre" item-value="idgrupo" prepend-icon="mdi-account-group" :disabled="loading || validClient" outlined dense required></v-autocomplete>
                  </v-col>
                </v-row>
              </div>
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Datos Generales</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-row>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.telefono_fijo" type="number" min="0" label="Teléfono fijo" prepend-icon="mdi-phone" :rules="rules.max13" :disabled="loading || validClient" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-text-field v-model="forms.person.telefono_movil" type="number" min="0" label="Teléfono móvil*" prepend-icon="mdi-cellphone" :rules="rules.max13" :disabled="loading || validClient" outlined dense required></v-text-field>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-select v-model="forms.person.estado_civil" label="Estado civil*" prepend-icon="mdi-account-multiple" :items="catalogue.estado_civil" item-text="id" item-value="value" :disabled="loading || validClient" outlined dense required></v-select>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete prepend-icon="mdi-home" v-model="forms.person.tipo_casa" :items="catalogue.tipo_casa" item-text="id" item-value="value" label="Tipo de vivienda*" :disabled="loading || validClient" outlined dense required></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="forms.person.email" label="Email" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading || validClient" outlined dense></v-text-field>
                  </v-col>
                  <v-col cols="12" md="4">
                    <div>
                      <v-menu ref="menu" v-model="menuDatePerson" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field v-model="forms.person.fecha_nacimiento" label="Fecha de nacimiento*" prepend-icon="mdi-calendar" :disabled="loading || validClient" readonly outlined dense v-bind="attrs" v-on="on" required></v-text-field>
                        </template>
                        <v-date-picker v-model="forms.person.fecha_nacimiento" :active-picker.sync="activePicker" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" min="1900-01-01" @change="$refs.menu.save(date)" locale="es-MX"></v-date-picker>
                      </v-menu>
                    </div>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="forms.person.ocupacion" label="Ocupación*" prepend-icon="mdi-briefcase-outline" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="12">
                    <v-textarea v-model="forms.person.detalle_ocupacion" label="Detalle de ocupación*" prepend-icon="mdi-briefcase-edit-outline" :rules="rules.max100" :disabled="loading || validClient" rows="1" outlined dense required></v-textarea>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <div class="text-right" v-if="!(e1 == 1)">
        <Basic-Btn text="Atras" color="primary" @click="e1 = e1 - 1" v-if="e1 > 1" :loading="loading" typeButton="text" />
        <Basic-Btn text="Siguiente" color="primary" @click="e1 = e1 + 1" v-if="e1 < 9" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" @click="requestAction()" v-if="(idForm ? e1 == 1 : false) || e1 == 5 || e1 == 6 || e1 == 8" :loading="loading" />
      </div>
      <v-stepper v-model="e1" v-if="e1 > 1" class="animate__animated animate__bounceInRight grey lighten-3" color="purple" flat>
        <v-stepper-items>
          <v-stepper-content step="2" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Dirección del solicitante</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-form ref="formPersonAddress" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.address.calle" label="Calle*" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.address.num_ext" label="Numero exterior*" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.address.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.address.colonia" label="Colonia*" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.address.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.address.cp" max="5" type="number" min="0" label="CP*" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.address.municipio" label="Municipio*" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.address.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.address.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.address.entre_calles" label="Entre calles*" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.address.referencia" label="Referencias*" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="3" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Datos del empleo</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-form ref="formPersonEmployee" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field v-model="forms.employee.nombre" label="Nombre del empleo*" prepend-icon="mdi-domain" :rules="rules.nombre.concat(rules.required)" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.employee.telefono" type="number" min="0" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.employeeAddress.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.employeeAddress.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.employeeAddress.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.employeeAddress.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.employeeAddress.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.employeeAddress.cp" type="number" min="0" label="CP" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.employeeAddress.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.employeeAddress.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.employeeAddress.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.employeeAddress.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.employeeAddress.referencia" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="4" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Familiar con ingresos</v-toolbar-title>
              </v-toolbar>
              <v-form ref="formRevenue" lazy-validation>
                <div class="container-padding">
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.clave_elector" @input="forms.revenuePerson.persona.clave_elector = forms.revenuePerson.persona.clave_elector.toUpperCase()" label="Clave de elector*" prepend-icon="mdi-card-account-details-outline" :rules="rules.max18" :disabled="loading" outlined dense required> </v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" v-if="!relationship.revenuePerson">
                      <v-select prepend-icon="mdi-account-multiple" v-model="forms.revenuePerson.parentesco" :items="catalogue.parentesco" item-text="id" item-value="value" label="Parentesco*" :disabled="loading || validClient" outlined dense required></v-select>
                    </v-col>
                    <v-col cols="12" md="3" v-if="relationship.revenuePerson">
                      <v-text-field v-model="forms.revenuePerson.parentesco" label="Detalle Parentesco*" prepend-icon="mdi-account-multiple" @input="forms.revenuePerson.parentesco = forms.revenuePerson.parentesco.toUpperCase()" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                      <Basic-Btn :text="relationship.revenuePerson ? 'Ver lista' : 'Otro'" color="primary" @click="relationship.revenuePerson = !relationship.revenuePerson" :loading="loading" />
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.telefono_movil" type="number" min="0" label="Telefono movil*" prepend-icon="mdi-cellphone" :rules="rules.max13" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.telefono_fijo" type="number" min="0" label="Telefono fijo" prepend-icon="mdi-phone" :rules="rules.max13" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select v-model="forms.revenuePerson.persona.estado_civil" label="Estado civil*" prepend-icon="mdi-account-multiple" :items="catalogue.estado_civil" item-text="id" item-value="value" :disabled="loading || validClient" outlined dense required></v-select>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-select prepend-icon="mdi-home" v-model="forms.revenuePerson.persona.tipo_casa" :items="catalogue.tipo_casa" item-text="id" item-value="value" label="Tipo de vivienda" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.email" label="Email" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <div>
                        <v-menu ref="menu" v-model="menuDateFamily" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="forms.revenuePerson.persona.fecha_nacimiento" label="Fecha de nacimiento*" prepend-icon="mdi-calendar" :disabled="loading || validClient" readonly outlined dense v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker v-model="forms.revenuePerson.persona.fecha_nacimiento" :active-picker.sync="activePicker" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" min="1900-01-01" @change="$refs.menu.save(date)" locale="es-MX"></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.ocupacion" label="Ocupación*" prepend-icon="mdi-briefcase-outline" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea v-model="forms.revenuePerson.persona.detalle_ocupacion" label="Detalle de ocupación*" prepend-icon="mdi-briefcase-edit-outline" :rules="rules.max100" :disabled="loading || validClient" rows="1" outlined dense></v-textarea>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.apellido_paterno" label="Apellido paterno*" prepend-icon="mdi-alpha-p" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.apellido_materno" label="Apellido materno" prepend-icon="mdi-alpha-m" :rules="rules.max200" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.persona.nombre" label="Nombres*" prepend-icon="mdi-account" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-toolbar class="mb-2" dense dark color="primary">
                  <v-toolbar-title tag="h2">Direccion de familiar con ingresos</v-toolbar-title>
                </v-toolbar>
                <div class="container-padding">
                  <div class="itemsRight"><v-checkbox v-model="sameHome" label="Comparte domicilio con el solicitante" color="primary" value="red" hide-details></v-checkbox></div>
                  <br />
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.revenuePerson.direccion_persona.cp" append-icon="" type="number" min="0" label="CP" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.revenuePerson.direccion_persona.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.revenuePerson.direccion_persona.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_persona.referencia" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
                <v-divider></v-divider>
                <v-toolbar class="mb-2" dense dark color="primary">
                  <v-toolbar-title tag="h2">Datos del empleo de familiar con ingresos</v-toolbar-title>
                </v-toolbar>
                <div class="container-padding">
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field v-model="forms.revenuePerson.empleo.nombre" label="Nombre del empleo" prepend-icon="mdi-domain" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.empleo.telefono" type="number" min="0" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.revenuePerson.direccion_empleo.cp" type="number" min="0" label="CP" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.revenuePerson.direccion_empleo.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select v-model="forms.revenuePerson.direccion_empleo.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.revenuePerson.direccion_empleo.referencia" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="5" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Avales</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-data-table :headers="headersEndorsement" :expanded.sync="expanded" :single-expand="true" item-key="id" show-expand :items="endorsement" hide-default-footer>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="addItem('endorsement')" :disabled="endorsement.length == 0" small fab dark>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item, 'aval', 'endorsement')">mdi-delete</v-icon></template
                      ><span>Eliminar</span></v-tooltip
                    >
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                      <v-toolbar class="mb-2" dense dark color="primary">
                        <v-toolbar-title tag="h2" v-text="'Datos del aval'" />
                      </v-toolbar>
                      <v-form ref="formsAvals" lazy-validation>
                        <v-stepper v-model="avalSteps" flat>
                          <v-stepper-items>
                            <v-stepper-content step="1">
                              <div class="container-padding">
                                <v-row>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.clave_elector" @input="item.persona.clave_elector = item.persona.clave_elector.toUpperCase()" label="Clave de elector*" prepend-icon="mdi-card-account-details-outline" :rules="rules.max18" :disabled="loading" outlined dense required> </v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.apellido_paterno" label="Apellido paterno*" prepend-icon="mdi-alpha-p" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.apellido_materno" label="Apellido materno" prepend-icon="mdi-alpha-m" :rules="rules.max200" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.nombre" label="Nombre(s)*" prepend-icon="mdi-account" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.telefono_fijo" type="number" min="0" label="Telefono fijo" prepend-icon="mdi-phone" :rules="rules.max13" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.telefono_movil" type="number" min="0" label="Telefono movil*" prepend-icon="mdi-cellphone" :rules="rules.max13" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-select v-model="item.persona.estado_civil" label="Estado civil" prepend-icon="mdi-account-multiple" :items="catalogue.estado_civil" item-text="id" item-value="value" :disabled="loading || validClient" outlined dense required></v-select>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-select prepend-icon="mdi-home" v-model="item.persona.tipo_casa" :items="catalogue.tipo_casa" item-text="id" item-value="value" label="Tipo de vivienda*" :disabled="loading || validClient" outlined dense required></v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.email" label="Email" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <div>
                                      <v-menu ref="menu" v-model="menuDateReference" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field v-model="item.persona.fecha_nacimiento" label="Fecha de nacimiento*" prepend-icon="mdi-calendar" :disabled="loading || validClient" readonly outlined dense v-bind="attrs" v-on="on" required></v-text-field>
                                        </template>
                                        <v-date-picker v-model="item.persona.fecha_nacimiento" :active-picker.sync="activePicker" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" min="1900-01-01" @change="$refs.menu.save(date)" locale="es-MX"></v-date-picker>
                                      </v-menu>
                                    </div>
                                  </v-col>
                                  <v-col cols="12" md="2" v-if="!relationship.aval">
                                    <v-select prepend-icon="mdi-account-multiple" v-model="item.parentesco" :items="catalogue.parentesco" item-text="id" item-value="value" label="Parentesco*" :disabled="loading || validClient" outlined dense required></v-select>
                                  </v-col>
                                  <v-col cols="12" md="2" v-if="relationship.aval">
                                    <v-text-field v-model="item.parentesco" label="Detalle Parentesco*" prepend-icon="mdi-account-multiple" @input="item.parentesco = item.parentesco.toUpperCase()" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="1">
                                    <Basic-Btn :text="relationship.aval ? 'Ver lista' : 'Otro'" color="primary" @click="relationship.aval = !relationship.aval" :loading="loading" />
                                  </v-col>
                                  <v-col cols="12" md="3">
                                    <v-text-field v-model="item.persona.ocupacion" label="Ocupación*" prepend-icon="mdi-briefcase-outline" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="12">
                                    <v-textarea v-model="item.persona.detalle_ocupacion" label="Detalle de ocupación*" prepend-icon="mdi-briefcase-edit-outline" :rules="rules.max100" :disabled="loading || validClient" rows="1" outlined dense required></v-textarea>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-stepper-content>

                            <v-stepper-content step="2">
                              <div class="container-padding">
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_persona.calle" label="Calle*" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_persona.num_ext" label="Numero exterior*" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_persona.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.num_int" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_persona.colonia" label="Colonia*" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_persona.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model.number="item.direccion_persona.cp" type="number" min="0" label="CP*" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_persona.municipio" label="Municipio*" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-select v-model="item.direccion_persona.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-select v-model="item.direccion_persona.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_persona.entre_calles" label="Entre calles*" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_persona.referencia" label="Referencias*" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-stepper-content>
                            <v-stepper-content step="3">
                              <div class="container-padding">
                                <v-row>
                                  <v-col cols="12" md="8">
                                    <v-text-field v-model="item.empleo.nombre" label="Nombre del empleo*" prepend-icon="mdi-domain" :rules="rules.nombre.concat(rules.required)" :disabled="loading || validClient" outlined dense required></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.empleo.telefono" type="number" min="0" label="Teléfono" prepend-icon="mdi-phone" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_empleo.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_empleo.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="4">
                                    <v-text-field v-model="item.direccion_empleo.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.num_int" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_empleo.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_empleo.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model.number="item.direccion_empleo.cp" type="number" min="0" label="CP" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_empleo.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-select v-model="item.direccion_empleo.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-select v-model="item.direccion_empleo.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_empleo.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field v-model="item.direccion_empleo.referencia" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense></v-text-field>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-stepper-content>
                            <div class="text-right container-padding">
                              <Basic-Btn text="Atras" color="primary" @click="avalSteps = avalSteps - 1" v-if="avalSteps > 1" :loading="loading" typeButton="text" />
                              <Basic-Btn text="Siguiente" color="primary" @click="avalSteps = avalSteps + 1" v-if="avalSteps < 3" :loading="loading" />
                              <Basic-Btn text="Guardar" color="success" @click="saveAval(item)" v-if="avalSteps == 3" :loading="loading" />
                            </div>
                          </v-stepper-items>
                        </v-stepper>
                      </v-form>
                      <br />
                    </td>
                  </template>

                  <template v-slot:[`item.articulo`]="props">
                    <v-edit-dialog :return-value.sync="props.item.articulo" large persistent>
                      <div>{{ props.item.articulo }}</div>
                      <template v-slot:input>
                        <br />
                        <v-text-field v-model="props.item.articulo" label="Articulo*" outlined dense autofocus required></v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="addItem('endorsement')" small fab dark>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="6" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Referencias personales</v-toolbar-title>
              </v-toolbar>

              <div class="container-padding">
                <v-data-table :headers="headersReference" :expanded.sync="expandedReference" :single-expand="true" item-key="id" show-expand :items="reference" hide-default-footer>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="addItem('reference')" :disabled="reference.length == 0" small fab dark>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item, 'personalReference', 'reference')">mdi-delete</v-icon></template
                      ><span>Eliminar</span></v-tooltip
                    >
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                      <v-toolbar class="mb-2" dense dark color="primary">
                        <v-toolbar-title tag="h2" v-text="'Datos de la referencia'" />
                      </v-toolbar>
                      <v-stepper v-model="referenceSteps" flat>
                        <v-stepper-items>
                          <v-stepper-content step="1">
                            <div class="container-padding">
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.clave_elector" @input="item.persona.clave_elector = item.persona.clave_elector.toUpperCase()" label="Clave de elector" prepend-icon="mdi-card-account-details-outline" :rules="rules.max18" :disabled="loading" outlined dense required> </v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.apellido_paterno" label="Apellido paterno*" prepend-icon="mdi-alpha-p" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.apellido_materno" label="Apellido materno" prepend-icon="mdi-alpha-m" :rules="rules.max200" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.nombre" label="Nombre(s)*" prepend-icon="mdi-account" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.telefono_fijo" type="number" min="0" label="Telefono fijo" prepend-icon="mdi-phone" :rules="rules.max13" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.telefono_movil" type="number" min="0" label="Telefono movil*" prepend-icon="mdi-cellphone" :rules="rules.max13" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select v-model="item.persona.estado_civil" label="Estado civil*" prepend-icon="mdi-account-multiple" :items="catalogue.estado_civil" item-text="id" item-value="value" :disabled="loading || validClient" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-select prepend-icon="mdi-home" v-model="item.persona.tipo_casa" :items="catalogue.tipo_casa" item-text="id" item-value="value" label="Tipo de vivienda" :disabled="loading || validClient" outlined dense required></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.email" label="Email" prepend-icon="mdi-email" :rules="rules.email" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                  <div>
                                    <v-menu ref="menu" v-model="menuDateReference" :close-on-content-click="false" transition="scale-transition" offset-y min-width="auto">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="item.persona.fecha_nacimiento" label="Fecha de nacimiento" prepend-icon="mdi-calendar" :disabled="loading || validClient" readonly outlined dense v-bind="attrs" v-on="on" required></v-text-field>
                                      </template>
                                      <v-date-picker v-model="item.persona.fecha_nacimiento" :active-picker.sync="activePicker" :max="new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10)" min="1900-01-01" @change="$refs.menu.save(date)" locale="es-MX"></v-date-picker>
                                    </v-menu>
                                  </div>
                                </v-col>
                                <v-col cols="12" md="3"> </v-col>
                                <v-col cols="12" md="2" v-if="!relationship.reference">
                                  <v-select prepend-icon="mdi-account-multiple" v-model="item.parentesco" :items="catalogue.parentesco" item-text="id" item-value="value" label="Parentesco*" :disabled="loading || validClient" outlined dense required></v-select>
                                </v-col>
                                <v-col cols="12" md="2" v-if="relationship.reference">
                                  <v-text-field v-model="item.parentesco" label="Detalle Parentesco" prepend-icon="mdi-account-multiple" @input="item.parentesco = item.parentesco.toUpperCase()" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="1">
                                  <Basic-Btn :text="relationship.reference ? 'Ver lista' : 'Otro'" color="primary" @click="relationship.reference = !relationship.reference" :loading="loading" />
                                </v-col>
                                <v-col cols="12" md="3">
                                  <v-text-field v-model="item.persona.ocupacion" label="Ocupación" prepend-icon="mdi-briefcase-outline" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="12">
                                  <v-textarea v-model="item.persona.detalle_ocupacion" label="Detalle de ocupación" prepend-icon="mdi-briefcase-edit-outline" :rules="rules.max100" :disabled="loading || validClient" rows="1" outlined dense></v-textarea>
                                </v-col>
                              </v-row>
                            </div>
                          </v-stepper-content>

                          <v-stepper-content step="2">
                            <div class="container-padding">
                              <v-row>
                                <v-col cols="12" md="4">
                                  <v-text-field v-model="item.direccion_persona.calle" label="Calle" prepend-icon="mdi-home-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field v-model="item.direccion_persona.num_ext" label="Numero exterior" prepend-icon="mdi-numeric-1-box-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                  <v-text-field v-model="item.direccion_persona.num_int" label="Numero interior" prepend-icon="mdi-numeric-2-box-outline" :rules="rules.num_int" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="item.direccion_persona.colonia" label="Colonia" prepend-icon="mdi-home-group" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="item.direccion_persona.sector" label="Sector" prepend-icon="mdi-home-city" :rules="rules.max100" :disabled="loading || validClient" outlined dense></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model.number="item.direccion_persona.cp" type="number" min="0" label="CP*" prepend-icon="mdi-pound" :rules="rules.cp_length" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="item.direccion_persona.municipio" label="Municipio" prepend-icon="mdi-home-city-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-select v-model="item.direccion_persona.estado" :items="catalogue.estado" item-text="id" item-value="value" label="Estado" prepend-icon="mdi-city" :disabled="loading || validClient" outlined dense></v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-select v-model="item.direccion_persona.pais" :items="catalogue.pais" item-text="id" item-value="value" label="País" prepend-icon="mdi-city-variant-outline" :disabled="loading || validClient" outlined dense></v-select>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="item.direccion_persona.entre_calles" label="Entre calles" prepend-icon="mdi-car-traction-control" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-text-field v-model="item.direccion_persona.referencia" label="Referencias" prepend-icon="mdi-home-search-outline" :rules="rules.nombre" :disabled="loading || validClient" outlined dense required></v-text-field>
                                </v-col>
                              </v-row>
                            </div>
                          </v-stepper-content>
                          <div class="text-right container-padding">
                            <Basic-Btn text="Atras" color="primary" @click="referenceSteps = referenceSteps - 1" v-if="referenceSteps > 1" :loading="loading" typeButton="text" />
                            <Basic-Btn text="Siguiente" color="primary" @click="referenceSteps = referenceSteps + 1" v-if="referenceSteps < 2" :loading="loading" />
                            <Basic-Btn text="Guardar" color="success" @click="savePersonalReference(item)" v-if="referenceSteps == 2" :loading="loading" />
                          </div>
                        </v-stepper-items>
                      </v-stepper>
                      <br />
                    </td>
                  </template>

                  <template v-slot:no-data>
                    <v-btn color="primary" @click="addItem('reference')" small fab dark>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="7" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Ingreso semanal promedio de la unidad familiar</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-form ref="formIncomeFamily" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.incomeFamily.ingresos_negocios" label="Ingresos por negocios*" prepend-icon="mdi-storefront-outline" prepend-inner-icon="mdi-currency-usd" :disabled="loading || validClient" outlined dense required type="number" min="0" step="0.01"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.incomeFamily.ingresos_empleos" label="Ingresos por empleos*" prepend-icon="mdi-briefcase-outline" prepend-inner-icon="mdi-currency-usd" :disabled="loading || validClient" outlined dense required type="number" min="0" step="0.01"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.incomeFamily.total_ingresos_semanal" label="total de ingresos semanal*" prepend-icon="mdi-cash" prepend-inner-icon="mdi-currency-usd" :disabled="loading || validClient" outlined dense required type="number" min="0" step="0.01"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.incomeFamily.total_gastos_semanal" label="Total de gastos semanal*" prepend-icon="mdi-cash-register" prepend-inner-icon="mdi-currency-usd" :disabled="loading || validClient" outlined dense required type="number" min="0" step="0.01"></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="8" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Recibo de Prenda</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-data-table :headers="headersPledges" :expanded.sync="expandedPledges" :single-expand="true" item-key="id" show-expand :items="pledges" hide-default-footer>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="addItem('pledges')" :disabled="pledges.length == 0" small fab dark>
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-tooltip top
                      ><template v-slot:activator="{ on, attrs }"><v-icon class="mr-2" size="22" color="error" dark v-bind="attrs" v-on="on" :disabled="loading" @click="remove(item, 'token', 'pledges')">mdi-delete</v-icon></template
                      ><span>Eliminar {{ item.id }}</span></v-tooltip
                    >
                  </template>

                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="px-0">
                      <v-toolbar class="mb-2" dense dark color="primary">
                        <v-toolbar-title tag="h2" v-text="'Datos del artículo'" />
                      </v-toolbar>
                      <div class="container-padding">
                        <v-row>
                          <v-col cols="12" md="3">
                            <v-text-field v-model="item.nombre" label="Articulo*" prepend-icon="mdi-cart" :rules="rules.max18" :disabled="loading" outlined dense required> </v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field v-model="item.marca" label="Marca*" prepend-icon="mdi-store" :rules="rules.max200" :disabled="loading || validClient" outlined dense required></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-text-field v-model="item.modelo" label="Modelo" prepend-icon="mdi-clipboard-list-outline" :rules="rules.max200" :disabled="loading || validClient" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" md="3">
                            <v-select v-model="item.tipo" :items="catalogue.tipo_prenda" item-text="id" item-value="value" label="Tipo*" prepend-icon="mdi-format-list-bulleted" :disabled="loading || validClient" outlined dense></v-select>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="item.ano" label="Año" prepend-icon="mdi-calendar-today" :rules="rules.max13" :disabled="loading || validClient" outlined dense></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="item.color" label="Color" prepend-icon="mdi-palette" :rules="rules.max13" :disabled="loading || validClient" outlined dense required></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field v-model="item.numero_serie" label="Numero de serie" prepend-icon="mdi-pound" :rules="rules.alfanumerico" :disabled="loading || validClient" outlined dense required></v-text-field>
                          </v-col>
                          <v-col cols="12" md="12">
                            <v-textarea v-model="item.observaciones" rows="1" label="Observaciones" prepend-icon="mdi-message" :rules="rules.max50" :disabled="loading || validClient" outlined dense required></v-textarea>
                          </v-col>
                        </v-row>
                      </div>

                      <div class="text-right container-padding">
                        <Basic-Btn text="Guardar" color="success" @click="savePledges(item)" :loading="loading" />
                      </div>
                    </td>
                  </template>
                  <template v-slot:no-data>
                    <v-btn color="primary" @click="addItem('pledges')" small fab dark>
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
              </div>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="10" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Datos de autorización</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-form ref="formAmount" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-select prepend-icon="mdi-card-account-details-outline" v-model="forms.amount.tipo_tarjeta" :items="catalogue.tipo_tarjeta" item-text="id" item-value="value" label="Tipo de tarjeta*" :disabled="loading || validClient || permissionCard" outlined dense required></v-select>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model="forms.amount.folio_tarjeta" label="Folio de tarjeta*" prepend-icon="mdi-credit-card-search-outline" :rules="rules.max20" :disabled="loading || validClient || permissionCard" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.amount.monto_aprobado" label="Monto autorizado*" prepend-icon="mdi-cash-check" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field v-model.number="forms.amount.monto_entregado" label="Monto entregado*" prepend-icon="mdi-cash-refund" :disabled="loading || validClient" outlined dense required></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-stepper-content>
          <v-stepper-content step="9" class="pa-0">
            <v-card class="animate__animated animate__bounceInRight mb-12">
              <v-toolbar class="mb-2" dense dark color="primary">
                <v-toolbar-title tag="h2">Observaciones</v-toolbar-title>
              </v-toolbar>
              <div class="container-padding">
                <v-form ref="formObservation" lazy-validation>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-textarea v-model="forms.observation.observaciones" label="Observaciones" prepend-icon="mdi-message" :rules="rules.max4000" :disabled="loading || validClient" outlined dense required></v-textarea>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
      <div class="text-right">
        <Basic-Btn text="Atras" color="primary" @click="e1 = e1 - 1" v-if="e1 > 1" :loading="loading" typeButton="text" />
        <Basic-Btn text="Siguiente" color="primary" @click="e1 = e1 + 1" v-if="e1 < 9" :loading="loading" />
        <Basic-Btn text="Guardar" color="success" @click="requestAction()" v-if="!((idForm ? e1 == 1 : false) || e1 == 2 || e1 == 5 || e1 == 6 || e1 == 8)" :loading="loading" />
      </div>
    </div>
    <Delete-Message :estate="dialogs.remove" msg="Usuario no existe">
      <template v-slot:buttons>
        <Basic-Btn text="Cancelar" color="disabled" icon="mdi-close" @click="dialogs.remove = false" :loading="loading" />
      </template>
    </Delete-Message>
    <Alerts-Message :estate="dialogs.alert" :alerts="alertData">
      <template v-slot:close>
        <Basic-Btn text="Cancelar" color="disabled" @click="dialogs.alert = false" block :loading="loading" />
      </template>
      <template v-slot:after>
        <Basic-Btn text="Continuar" color="primary" @click="alertAction()" block :loading="loading" />
      </template>
    </Alerts-Message>
    <BasicAlert :active.sync="alert.active" :msg="alert.msg" :color="alert.color" :errors.sync="alert.errors" :errorSettings="{ key: 'value', valid: 'required' }" />
  </v-container>
</template>

<script>
import "animate.css";
import { services, errorsList } from "@/utils";
import { Loader, DialogForm, BasicAlert, TableContainer, BasicBtn, DeleteMessage, AlertsMessage } from "@/components";
import _ from 'lodash'

export default {
  name: "newClient",
  components: {
    Loader,
    DialogForm,
    BasicAlert,
    TableContainer,
    BasicBtn,
    DeleteMessage,
    AlertsMessage,
  },
  data: () => ({
    loading: false,
    validClient: true,
    menuDatePerson: false,
    menuDateFamily: false,
    menuDateReference: false,
    sameHome: false,
    update: false,
    avalSteps: 1,
    referenceSteps: 1,
    companyId: 0,
    groupId: 0,
    idForm: null,
    activePicker: "YEAR",
    expanded: [],
    expandedReference: [],
    expandedPledges: [],
    company: [],
    group: [],
    reference: [],
    alertData: [],
    requestedAmount: 0,
    suggestedAmount: 0,
    card_type: "",
    card_id: "",
    panel: 0,
    houseItems: ["propia", "familiar"],
    civilItems: ["Soltero", "Casado", "Divorciado", "Separación en proceso judicial", "Viudo", "Concubinato"],
    familyItems: ["Abuelo", "Padre", "Madre", "Hijo", "Tio", "Primo", "Sobrino", "Nieto", "Cónyugue"],
    catalogue: [],
    roles: [],
    ids: {
      revenuePerson: null,
      personalReference: null,
      aval: null,
      revenu: null,
      token: null,
      observation: null,
    },
    alert: {
      active: false,
      color: "error",
      msg: "",
      errors: [],
    },
    forms: {
      person: {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        estado_civil: "",
        clave_elector: "",
        telefono_fijo: "",
        telefono_movil: "",
        email: "",
        ocupacion: "",
        detalle_ocupacion: "",
        tipo_casa: "",
        tipo_tarjeta: "",
        folio_tarjeta: "",
      },
      address: {
        calle: null,
        num_int: null,
        num_ext: null,
        colonia: null,
        sector: null,
        municipio: null,
        estado: null,
        pais: "MEXICO",
        entre_calles: null,
        referencia: null,
        cp: null,
      },
      employee: {
        nombre: null,
        telefono: null,
      },
      employeeAddress: {
        calle: null,
        num_int: null,
        num_ext: null,
        colonia: null,
        sector: null,
        municipio: null,
        estado: null,
        pais: "MEXICO",
        entre_calles: null,
        referencia: null,
        cp: null,
      },
      incomePerson: {
        nombre: "",
        apellido_paterno: "",
        apellido_materno: "",
        fecha_nacimiento: "",
        estado_civil: "",
        clave_elector: "",
        parentesco: "",
        fecha_nacimiento: null,
        address: {
          calle: null,
          num_int: null,
          num_ext: null,
          colonia: null,
          sector: null,
          municipio: null,
          estado: null,
          pais: "MEXICO",
          entre_calles: null,
          referencias: null,
        },
        employee: {
          nombre: null,
          telefono: null,
          calle: null,
          num_int: null,
          num_ext: null,
          colonia: null,
          sector: null,
          municipio: null,
          estado: null,
          pais: "MEXICO",
          entre_calles: null,
          referencias: null,
        },
      },
      incomeFamily: {
        ingresos_negocios: 0,
        ingresos_empleos: 0,
        total_ingresos_semanal: 0,
        total_gastos_semanal: 0,
      },
      amount: {
        tipo_tarjeta: "",
        folio_tarjeta: "",
        monto_aprobado: 0,
        monto_entregado: 0,
      },
      observation: {
        observaciones: "",
      },
      revenuePerson: {
        parentesco: null,
        persona: {
          nombre: null,
          apellido_paterno: null,
          apellido_materno: null,
          fecha_nacimiento: null,
          estado_civil: null,
          clave_elector: null,
          telefono_fijo: null,
          telefono_movil: null,
          email: null,
          ocupacion: null,
          detalle_ocupacion: null,
          tipo_casa: null,
        },
        direccion_persona: {
          calle: null,
          num_ext: null,
          num_int: null,
          entre_calles: null,
          referencia: null,
          colonia: null,
          sector: null,
          cp: null,
          municipio: null,
          estado: null,
          pais: "MEXICO",
        },
        empleo: {
          nombre: null,
          telefono: null,
        },
        direccion_empleo: {
          calle: null,
          num_ext: null,
          num_int: null,
          entre_calles: null,
          referencia: null,
          colonia: null,
          sector: null,
          cp: null,
          municipio: null,
          estado: null,
          pais: "MEXICO",
        },
      },
    },
    relationship: {
      revenuePerson: false,
      aval: false,
      reference: false,
    },
    dialogs: {
      remove: false,
      alert: false,
    },
    rules: {
      required: [(v) => !!v || "El campo es requerido"],
      onlyText: [(v) => /[a-zA-Z]+$/.test(v) || "Formato no valido.", (v) => (_.isNil(v) || v.length <= 200) || "El campo excede la longitud máxima"],
      email: [
        (value) => {
          if (value) {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Invalid e-mail.";
          }
          return true
        },
      ],
      alfanumerico: [
        (v) => (_.isNil(v) || v.length) <= 50 || "La contraseña excede la longitud máxima",
        (v) => /^(?=.*[A-Za-z])(?=.*\d)[A-Za-zñÑ\d__-]{1,}$/.test(v) || "El campo no acepta caracteres especiales."
      ],
      nombre: [(v) => (_.isNil(v) || v.length) <= 100 || "El campo excede la longitud máxima"],
      apellido_paterno: [(v) => (_.isNil(v) || v.length) <= 100 || "El campo excede la longitud máxima"],
      apellido_materno: [(v) => (_.isNil(v) || v.length) <= 100 || "El campo excede la longitud máxima"],
      telefono: [(v) => (_.isNil(v) || v.length) <= 15 || "El campo excede la longitud máxima"],
      addresValid: [(v) => !!v || "El campo es requerido"],
      max20: [(v) => (_.isNil(v) || v.length) <= 20 || "El campo excede la longitud máxima"],
      max13: [(v) => (_.isNil(v) || v.length) <= 13 || "El campo excede la longitud máxima"],
      max30: [(v) => (_.isNil(v) || v.length) <= 30 || "El campo excede la longitud máxima"],
      max50: [(v) => (_.isNil(v) || v.length) <= 50 || "El campo excede la longitud máxima"],
      max100: [(v) => (_.isNil(v) || v.length) <= 100 || "El campo excede la longitud máxima"],
      max200: [(v) => (_.isNil(v) || v.length) <= 200 || "El campo excede la longitud máxima"],
      max18: [(v) => (_.isNil(v) || v.length) <= 18 || "El campo excede la longitud máxima"],
      max4000: [(v) => (_.isNil(v) || v.length) <= 4000 || "El campo excede la longitud máxima"],
      addres100: [(v) => (_.isNil(v) || v.length) <= 100 || "El campo excede la longitud máxima"],
      cp_length: [(v) => (_.isNil(v) || v.toString().length <= 5) || "El campo excede la longitud máxima"],
    },
    e1: 1,
    steps: ["Datos de solicitante", "Direccion", "Empleo", "Personas con ingresos", "Avales", "Referencias personales", "Ingreso semanal", "Prendas", "Observaciones generales", "Datos de autorización"],
    headersPledges: [
      {
        text: "Artículo",
        value: "nombre",
      },
      { text: "Marca", value: "marca" },
      { text: "Modelo", value: "modelo" },
      { text: "Tipo", value: "tipo" },
      { text: "Año", value: "ano" },
      { text: "Color", value: "color" },
      { text: "Serie", value: "numero_serie" },
      { text: "", value: "data-table-expand" },
      { text: "Acciones", value: "actions" },
    ],
    pledges: [],
    headersReference: [
      {
        text: "Nombre(s)",
        value: "persona.nombre",
      },
      { text: "Apellido paterno", value: "persona.apellido_paterno" },
      { text: "Apellido materno", value: "persona.apellido_materno" },
      { text: "Clave de elector", value: "persona.clave_elector" },
      { text: "Casa propia", value: "persona.tipo_casa" },
      { text: "Teléfono fijo", value: "persona.telefono_fijo" },
      { text: "Teléfono movil", value: "persona.telefono_movil" },
      {
        text: "Fecha de nacimiento",
        value: "persona.fecha_nacimiento",
      },
      {
        text: "Estado civil",
        value: "persona.estado_civil",
      },
      { text: "", value: "data-table-expand" },

      { text: "Acciones", value: "actions" },
    ],
    headersEndorsement: [
      {
        text: "Nombre(s)",
        value: "persona.nombre",
      },
      { text: "Apellido paterno", value: "persona.apellido_paterno" },
      { text: "Apellido materno", value: "persona.apellido_materno" },
      { text: "Clave de elector", value: "persona.clave_elector" },
      { text: "Casa propia", value: "persona.tipo_casa" },
      { text: "Teléfono fijo", value: "persona.telefono_fijo" },
      { text: "Teléfono movil", value: "persona.telefono_movil" },
      {
        text: "Fecha de nacimiento",
        value: "persona.fecha_nacimiento",
      },
      {
        text: "Estado civil",
        value: "persona.estado_civil",
      },
      { text: "", value: "data-table-expand" },

      { text: "Acciones", value: "actions" },
    ],
    endorsement: [],
  }),
  computed: {
    permissionCard: function () {
      this.roles = this.$session.get("roles");
      let value = true;
      for (let rol of this.$session.get("roles")) {
        if (rol == "captura tarjeta") {
          value = false;
        }
      }
      return value;
    },
  },
  mounted() {
    let id = this.$route.params.id;
    this.getCatalogue();

    if (id) {
      this.validClient = false;
      this.update = true;
      this.e1 = parseInt(this.$route.params.section);
      this.idForm = parseInt(id);
      this.serachAvaibleInfo(id);
    }
    this.getCompany();
    this.getGroup();
  },
  watch: {
    menuDatePerson(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    sameHome(val) {
      this.shareHome(val);
    },
  },
  methods: {
    addItem(element) {
      if (element == "pledges") {
        this.pledges.push({
          nombre: "pendiente",
          marca: null,
          modelo: null,
          tipo: null,
          ano: null,
          color: null,
          numero_serie: null,
          observaciones: null,
        });
      } else if (element == "endorsement") {
        this.endorsement.push({
          id: this.reference.length + this.endorsement.length,
          solicitud: null,
          parentesco: null,
          persona: {
            nombre: "Pendiente",
            apellido_paterno: null,
            apellido_materno: null,
            fecha_nacimiento: null,
            estado_civil: null,
            clave_elector: null,
            telefono_fijo: null,
            telefono_movil: null,
            email: null,
            ocupacion: null,
            detalle_ocupacion: null,
            tipo_casa: null,
          },
          direccion_persona: {
            calle: null,
            num_ext: null,
            num_int: null,
            entre_calles: null,
            referencia: null,
            colonia: null,
            sector: null,
            cp: null,
            municipio: null,
            estado: null,
            pais: "MEXICO",
          },
          empleo: {
            nombre: null,
            telefono: null,
          },
          direccion_empleo: {
            calle: null,
            num_ext: null,
            num_int: null,
            entre_calles: null,
            referencia: null,
            colonia: null,
            sector: null,
            cp: null,
            municipio: null,
            estado: null,
            pais: "MEXICO",
          },
        });
      } else if (element == "reference") {
        this.reference.push({
          solicitud: null,
          parentesco: null,
          persona: {
            nombre: "Pendiente",
            apellido_paterno: '',
            apellido_materno: '',
            fecha_nacimiento: null,
            estado_civil: '',
            clave_elector: '',
            telefono_fijo: '',
            telefono_movil: '',
            email: '',
            ocupacion: '',
            detalle_ocupacion: '',
            tipo_casa: '',
          },
          direccion_persona: {
            calle: '',
            num_ext: '',
            num_int: '',
            entre_calles: '',
            referencia: '',
            colonia: '',
            sector: '',
            cp: null,
            municipio: '',
            estado: '',
            pais: "MEXICO",
          },
        });
      }
    },
    lessItem(item, element) {
      this[element].splice(this[element].indexOf(item), 1);
    },
    serachAvaibleInfo(id) {
      this.loading = true;
      let request = {
        params: {
          id: id,
        },
      };
      this.axios
        .get(services.routes.loan + "/detail", request)
        .then((response) => {
          let data = response.data.data;
          this.companyId = data.general.empresa;
          this.groupId = data.general.grupo;
          this.requestedAmount = parseFloat(data.general.monto_solicitado);
          this.suggestedAmount = parseFloat(data.general.monto_sugerido);
          for (let key in data.general.persona) {
            this.forms.person[key] = data.general.persona[key];
          }
          for (let key in data.general.direccion_persona) {
            this.forms.address[key] = data.general.direccion_persona[key];
          }
          for (let key in data.persona_ingreso) {
            this.forms.revenuePerson[key] = data.persona_ingreso[key];

            if (key == "parentesco") {
              this.relationship.revenuePerson = this.catalogue.parentesco.filter((i) => i.value === this.forms.revenuePerson["parentesco"]).length ? false : true;
            }
          }
          for (let key in data.general.empleo) {
            this.forms.employee[key] = data.general.empleo[key];
          }
          for (let key in data.general.direccion_empleo) {
            this.forms.employeeAddress[key] = data.general.direccion_empleo[key];
          }
          for (let key in data.ingresos) {
            this.forms.incomeFamily[key] = parseFloat(data.ingresos[key]);
          }
          for (let key in data.observaciones) {
            this.forms.observation[key] = data.observaciones[key];
          }
          for (let info of data.prendas) {
            info.prenda.id = info.id;
            this.pledges.push(info.prenda);
          }
          for (let info of data.referencias_personales) {
            this.reference.push(info);
          }
          for (let info of data.avales) {
            this.endorsement.push(info);
          }
          this.forms.amount.id = data.general.id ? data.general.id : 0;
          this.forms.amount.folio_tarjeta = data.general.folio_tarjeta ? data.general.folio_tarjeta : "";
          this.forms.amount.tipo_tarjeta = data.general.tipo_tarjeta ? data.general.tipo_tarjeta : "";
          this.forms.amount.monto_aprobado = data.general.monto_aprobado ? data.general.monto_aprobado : 0;
          this.forms.amount.monto_entregado = data.general.monto_entregado ? data.general.monto_entregado : 0;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPerson() {
      this.loading = true;
      let request = {
        params: {
          clave_elector: this.forms.person.clave_elector,
        },
      };
      this.axios
        .get(services.routes.client + "/find/", request)
        .then((response) => {
          let data = response.data.data;
          this.companyId = data.empresa;
          this.groupId = data.grupo;
          this.requestedAmount = parseFloat(data.monto_solicitado);
          this.suggestedAmount = parseFloat(data.monto_sugerido);
          for (let key in data.persona) {
            this.forms.person[key] = "hey";
          }
          for (let key in data.direccion_persona) {
            this.forms.address[key] = data.direccion_persona[key];
          }
          for (let key in data.persona_ingreso) {
            this.forms.revenuePerson[key] = data.persona_ingreso[key];
          }
          for (let key in data.empleo) {
            this.forms.employee[key] = data.empleo[key];
          }
          for (let key in data.direccion_empleo) {
            this.forms.employeeAddress[key] = data.direccion_empleo[key];
          }
          for (let key in data.ingresos) {
            this.forms.incomeFamily[key] = parseFloat(data.ingresos[key]);
          }
          for (let key in data.observaciones) {
            this.forms.observation[key] = data.observaciones[key];
          }
          for (let info of data.prendas) {
            info.prenda.id = info.id;
            this.pledges.push(info.prenda);
          }
          for (let info of data.referencias_personales) {
            this.reference.push(info);
          }
          for (let info of data.avales) {
            this.endorsement.push(info);
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.validClient = false;
          this.loading = false;
        });
    },
    async getCompany() {
      this.axios.get(services.routes.company).then((response) => {
        this.company = response.data.data;
      });
    },
    async getGroup() {
      this.axios
        .get(services.routes.group)
        .then((response) => {
          this.group = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getCatalogue() {
      this.axios
        .get(services.routes.catalogue)
        .then((response) => {
          this.catalogue = response.data.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    requestAction() {
      if (this.e1 == 1) {
        this.getValidate("create");
      } else if (this.e1 == 3) {
        this.savePerson();
      } else if (this.e1 == 4) {
        this.saveRevenuePerson();
      } else if (this.e1 == 5) {
        this.createPerson();
      } else if (this.e1 == 6) {
        this.createPerson();
      } else if (this.e1 == 7) {
        this.saveIncome();
      } else if (this.e1 == 8) {
        this.createPerson();
      } else if (this.e1 == 9) {
        this.saveObservation();
      } else if (this.e1 == 10) {
        this.saveAmount();
      }
    },
    errorValidate(array, type) {
      let auxObject = JSON.parse(JSON.stringify(errorsList[type]));
      let valid = false;
      for (let key in array) {
        if (auxObject[key] ? auxObject[key]["required"] : false) {
          if (!array[key]) {
            valid = true;
            auxObject[key]["required"] = true;
          } else if (array[key]) {
            auxObject[key]["required"] = false;
          }
        }
      }
      if (valid) {
        this.alert.errors = auxObject;
        this.alert.msg = "Campos con informacion incompleta:";
        return true;
      }
      return valid;
    },
    createPerson() {
      this.loading = true;
      let data = {
        empresa: this.companyId,
        grupo: this.groupId,
        monto_sugerido: this.requestedAmount,
        monto_solicitado: this.suggestedAmount,
        persona: this.forms.person,
        persona: {
          nombre: this.forms.person.nombre,
          apellido_paterno: this.forms.person.apellido_paterno,
          apellido_materno: this.forms.person.apellido_materno,
          clave_elector: this.forms.person.clave_elector,
        },
      };

      this.axios
        .post(services.routes.loan, { data })
        .then((response) => {
          this.alert.active = true;
          this.alert.msg = "Usuario creado exitosamente.";
          this.alert.color = "success";
          this.idForm = response.data.data;
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          if (!this.errorValidate(this.forms.person, "details")) {
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    savePerson() {
      let valid = false;
      if (!this.errorValidate(this.forms.person, "details") && !this.errorValidate(this.forms.address, "address") && !this.errorValidate(this.forms.employeeAddress, "address")) {
        valid = true;
      }
      if (valid) {
        this.loading = true;
        let data = {
          empresa: this.companyId,
          grupo: this.groupId,
          monto_sugerido: this.requestedAmount,
          monto_solicitado: this.suggestedAmount,
          persona: this.forms.person,
          tipo_tarjeta: this.cardType,
          folio_tarjeta: this.cardId,
          direccion_persona: this.forms.address,
          empleo: this.forms.employee,
          direccion_empleo: this.forms.employeeAddress,
        };

        if (this.idForm) {
          data.id = this.idForm;
        }
        this.axios
          .put(services.routes.loan, { data })
          .then((response) => {
            this.alert.active = true;
            if (this.idForm) {
              this.alert.msg = "Usuario actualizado exitosamente.";
            } else {
              this.alert.msg = "Usuario creado exitosamente.";
            }
            this.alert.color = "success";
            this.idForm = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            this.alert.msg = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    savePersonalReference(item) {
      let address = true;
      for (let key in item.direccion_persona) {
        if (!item.direccion_persona[key]) {
          address = false;
        }
      }
      if (item.persona.nombre && item.persona.apellido_paterno && item.parentesco && item.persona.telefono_movil && item.persona.estado_civil) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          parentesco: item.parentesco,
          persona: item.persona,
          direccion_persona: item.direccion_persona,
        };
        if (item.id) {
          data.id = item.id;
        }
        this.axios
          .put(services.routes.loan + "/personalReference", { data })
          .then((response) => {
            this.alert.active = true;
            if (this.idForm) {
              this.alert.msg = "Referencia actualizada exitosamente.";
            } else {
              this.alert.msg = "Referencia creada exitosamente.";
            }
            this.alert.color = "success";
            item.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(item.persona, "detailsReference")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    saveAval(item) {
      let address = true;
      let employee = true;
      for (let key in item.direccion_persona) {
        if (!item.direccion_persona[key]) {
          address = false;
        }
      }
      for (let key in item.direccion_empleo) {
        if (!item.direccion_persona[key]) {
          employee = false;
        }
      }
      if (this.$refs.formsAvals.validate()) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          parentesco: item.parentesco,
          persona: item.persona,
          direccion_persona: item.direccion_persona,
          empleo: item.empleo,
          direccion_empleo: item.direccion_empleo,
        };
        if (item.id) {
          data.id = item.id;
        }
        this.axios
          .put(services.routes.loan + "/aval", { data })
          .then((response) => {
            this.alert.color = "success";
            if (this.idForm) {
              this.alert.msg = "Aval actualizado exitosamente.";
            } else {
              this.alert.msg = "Aval creado exitosamente.";
            }
            this.alert.active = true;
            item.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(item.persona, "details") && !this.errorValidate(item.direccion_persona, "address") && !this.errorValidate(item.direccion_empleo, "address")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    saveIncome() {
      if (this.$refs.formIncomeFamily.validate()) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          ingresos: this.forms.incomeFamily,
        };
        if (this.forms.incomeFamily.id) {
          data.id = this.forms.incomeFamily.id;
        }
        this.axios
          .put(services.routes.loan + "/revenue", { data })
          .then((response) => {
            this.alert.active = true;
            if (this.idForm) {
              this.alert.msg = "Ingresos actualizados exitosamente.";
            } else {
              this.alert.msg = "Ingresos creados exitosamente.";
            }
            this.alert.color = "success";
            this.forms.incomeFamily.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(this.forms.incomeFamily, "income")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    savePledges(item) {
      let valid = false;
      if (!this.errorValidate(item, "pledge")) {
        valid = true;
      }
      if (valid) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          prenda: item,
        };
        if (item.id) {
          data.id = item.id;
        }
        this.axios
          .put(services.routes.loan + "/token", { data })
          .then((response) => {
            this.alert.color = "success";
            if (this.idForm) {
              this.alert.msg = "Prenda actualizada exitosamente.";
            } else {
              this.alert.msg = "Prenda creada exitosamente.";
            }
            this.alert.active = true;
            item.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(item, "pledge")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    saveObservation() {
      if (this.$refs.formObservation.validate()) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          observaciones: this.forms.observation.observaciones,
        };
        if (this.forms.observation.id) {
          data.id = this.forms.observation.id;
        }
        this.axios
          .put(services.routes.loan + "/observation", { data })
          .then((response) => {
            this.alert.active = true;
            if (this.forms.observation.id) {
              this.alert.msg = "Observacion actualizada exitosamente.";
            } else {
              this.alert.msg = "Observacion creada exitosamente.";
            }
            this.alert.color = "success";
            this.forms.revenuePerson.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(this.forms.observation, "observation")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    saveRevenuePerson() {
      if (this.$refs.formRevenue.validate()) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          parentesco: this.forms.revenuePerson.parentesco,
          persona: this.forms.revenuePerson.persona,
          direccion_persona: this.forms.revenuePerson.direccion_persona,
          empleo: this.forms.revenuePerson.empleo,
          direccion_empleo: this.forms.revenuePerson.direccion_empleo,
        };
        if (this.forms.revenuePerson.id) {
          data.id = this.forms.revenuePerson.id;
        }
        this.axios
          .put(services.routes.loan + "/revenuePerson", { data })
          .then((response) => {
            this.alert.active = true;
            if (this.forms.revenuePerson.id) {
              this.alert.msg = "Familiar actualizado exitosamente.";
            } else {
              this.alert.msg = "Familiar creado exitosamente.";
            }
            this.alert.color = "success";
            this.forms.revenuePerson.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(this.forms.revenuePerson.persona, "details") && !this.errorValidate(this.forms.revenuePerson, "family")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    saveAmount() {
      if (this.$refs.formAmount.validate()) {
        this.loading = true;
        let data = {
          solicitud: this.idForm,
          tipo_tarjeta: this.forms.amount.tipo_tarjeta,
          folio_tarjeta: this.forms.amount.folio_tarjeta,
          monto_aprobado: parseFloat(this.forms.amount.monto_aprobado),
          monto_entregado: parseFloat(this.forms.amount.monto_entregado),
        };
        if (this.forms.amount.id) {
          data.id = this.forms.amount.id;
        }
        this.axios
          .put(services.routes.loan + "/amount", { data })
          .then((response) => {
            this.alert.active = true;
            if (this.forms.amount.id) {
              this.alert.msg = "Informacion de tarjetas actualizado exitosamente.";
            } else {
              this.alert.msg = "Informacion de tarjetas creado exitosamente.";
            }
            this.alert.color = "success";
            this.forms.amount.id = response.data.data;
          })
          .catch((error) => {
            this.alert.active = true;
            this.alert.color = "error";
            if (!this.errorValidate(this.forms.amount, "amount")) {
              this.alert.msg = error.response.data.message || "Servicio no disponible";
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Los datos son incorrectos";
      }
    },
    remove(item, location, reference) {
      let data = {
        solicitud: this.idForm,
        id: item.id,
      };
      if (item.id) {
        this.loading = true;
        this.axios
          .delete(services.routes.loan + "/" + location, { data: { data } })
          .then((response) => {
            this.lessItem(item, reference);
            this.success = true;
            this.msgSuccess = "Elemento eliminado exitosamente.";
          })
          .catch((error) => {
            this.error = true;
            this.msgError = error.response.data.message || "Servicio no disponible";
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.alert.active = true;
        this.alert.color = "error";
        this.alert.msg = "Error al eliminar intentelo mas tarde.";
      }
    },
    shareHome(val) {
      for (let key in this.forms.address) {
        this.forms.revenuePerson.direccion_persona[key] = val ? this.forms.address[key] : "";
      }
      this.alert.active = true;
      this.alert.color = "success";
      this.alert.msg = "Direccion actualizada.";
    },
    getValidate(type) {
      this.loading = true;
      let request = {
        params: {
          clave_elector: this.forms.person.clave_elector,
        },
      };
      this.axios
        .get(services.routes.alert, request)
        .then((response) => {
          this.alertData = response.data.data;
          if (this.alertData.length > 0) {
            this.dialogs.alert = true;
          } else {
            this.alertAction(type);
          }
        })
        .catch((error) => {
          this.alert.active = true;
          this.alert.color = "error";
          this.alert.msg = error.response.data.message || "Servicio no disponible";
        })
        .finally(() => {
          this.loading = false;
        });
    },
    alertAction(action) {
      if (action == "create") {
        this.createPerson();
      } else {
        this.getPerson();
      }
    },
    upper(e) {
      e.target.value = e.target.value.toUpperCase();
    },
  },
};
</script>

<style scoped>
  .titulo {
    background: #222665;
    color: white;
    padding: 15px;
    display: inline-block;
    border-radius: 0px 0px 20px 20px !important;
    margin-bottom: 1px;
  }
  .tituloAux {
    background: #222665;
    color: white;
    padding: 15px;
    display: inline-block;
    border-radius: 0px 0px 20px 20px !important;
    margin-bottom: 1px;
  }
  .mainTitle {
    background: #222665;
    color: white;
    padding: 15px;
    display: inline-block;
    border-radius: 20px !important;
    margin-top: 1px;
    margin-bottom: 2px;
  }
  .container-padding {
    padding: 1vw !important;
    padding-top: 5px !important;
  }
</style>
